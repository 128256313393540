/* styles for invoice list & reports page */

.my-page-content {
    padding-top: 70px;
    padding-bottom: 50px;
}

.custom-label {
    width: 38% !important;
    /* justify-content-around  */
    align-items:center;
    text-align: center !important;
  }

.custom-input {
    width: 60% !important;
    border-radius: 5px;
    border: 1px solid rgb(175 139 231);
    cursor: auto !important;
  }

.custom-input-area {
    cursor: auto !important;
  }

.payment-box{
    background-color: #fff;
    padding: 10px;
    border: 1px solid #DBE0E7;
    border-radius: 5px;
    height: 100%;
    min-height: 104px;
    box-shadow: 10px 10px 5px lightblue;
}

.top-row-input{
    border-radius: 5px;
    border: 1px solid rgb(175 139 231);
    padding: 5px;
    margin-bottom: 3px;
    margin-right: 0.5rem; 
}

.top-row-button{
    border-radius: 5px;
    border: 1px solid rgb(175 139 231);
    padding: 5px;
    margin-bottom: 3px;
    margin-right: 0.5rem; 
    background-color: #daf4f7;
    box-shadow: 3px 3px 1px rgb(216, 226, 226);

}

.top-row-button:hover{
    background-color: rgb(183, 241, 221);
}

.total-row{
    font-size: 15px;
    font-weight: bold;
    
}

/* .table-th{
    word-wrap: normal;
    word-break: keep-all;
} */